import * as React from 'react';
import '../styles/login.css';
import {styled as muiStyled} from '@mui/material/styles';
import {
    TextField, Alert, Select, InputAdornment, IconButton, Tooltip, Snackbar, SelectChangeEvent, MenuItem, FormControl, InputLabel,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {PageProps} from 'gatsby';
import {IGraphQLMenuEntry} from '../../graphql.types';
import {IUpdateMenuItemsProps, updateMenuItems} from '../state/menu-items.slice';
import {connect} from 'react-redux';
import {mapStateToProps} from '../state/store';

interface IPixelGeneratorData {
    title: string;
    content: string;
    menuItems: Array<IGraphQLMenuEntry>;
}

interface IPixelGeneratorState {
    orderId: string;
    publisher: Publisher | '';
    platform: Platform | '';
    adType: AdType | '';
    isCopySnackbarOpen: boolean;
}

enum Publisher {
    SOM = 'SOM',
    AdAlliance = 'AdAlliance'
}

enum Platform {
    ATV = 'ATV',
    Video = 'Video',
    Display = 'Display'
}

enum AdType {
    PreRoll = 'PreRoll',
    PreMidRoll = 'PreMidRoll',
    SwitchIn = 'SwitchIn',
    ATVSpot = 'ATVSpot',
    SwitchInSpot = 'SwitchInSpot',
    Billboard = 'Billboard',
    Sitebar = 'Sitebar',
    MobileBanner = 'MobileBanner',
    Understitial = 'Understitial',
    MobileRectangle = 'MobileRectangle',
    AdBundle = 'AdBundle',
}

type IPixelGeneratorProps = PageProps<any, IPixelGeneratorData>;

class PixelGenerator extends React.Component<IPixelGeneratorProps & IUpdateMenuItemsProps, IPixelGeneratorState> {

    constructor(props: IPixelGeneratorProps & IUpdateMenuItemsProps) {
        super(props);

        this.state = {
            orderId: '',
            publisher: '',
            platform: '',
            adType: '',
            isCopySnackbarOpen: false,
        };
        this.handleCopyClick = this.handleCopyClick.bind(this);
        this.handlePixelFocus = this.handlePixelFocus.bind(this);
    }

    public componentDidMount() {
        this.props.updateMenuItems(this.props.pageContext.menuItems);
    }

    public render(): React.ReactNode {
        return (
            <section style={{padding: '16px'}}>
                <h1 style={{marginTop: 0}}>Pixelgenerator</h1>
                <div>
                    <form className="pixel-form">
                        <Alert severity="info">
                            <span>
                                Generieren Sie Ihren Tracking-Pixel, indem Sie die Order-ID eingeben und Vermarkter,
                                Plattform sowie Werbeform auswählen.
                            </span>
                            <p>
                                Die generierten Pixel können neben dem Reporting auf Haushaltsebene auch für die Sammlung von Usern bei
                                ATV-Basis-Kampagnen genutzt werden.
                            </p>
                            <p>
                                Für d-force Kampagnen (≠ SOM-Only-Kampagnen oder AdA-Only-Kampagnen) gilt:
                            </p>
                            <ul>
                                <li>Eine Insertion Order: Fügen Sie Ihre Insertion Order ID aus der Active Agent DSP
                                    ein,
                                    die individuell für Ihre Cross Device Kampagne gilt.
                                    Bitte teilen Sie diese Order ID Ihrer/Ihrem Kundenberater:in mit.
                                </li>
                                <li>Mehrere Insertion Orders: Wählen Sie <u>eine</u> der bestehenden Insertion Order IDs für
                                    die gesamte Cross Device Kampagne aus.
                                    Bitte teilen Sie diese Order ID Ihrer/Ihrem Kundenberater:in mit.
                                </li>
                            </ul>
                            <p>
                                Die korrekte Hinterlegung des Pixels im Creative ist essenziell, um ein Reporting auf Haushaltsebene zu
                                ermöglichen.
                            </p>
                        </Alert>
                        {this.getOrderIdFormControl()}
                        {this.getPublisherFormControl()}
                        {this.getPlatformFormControl()}
                        {this.getAdTypeFormControl()}
                    </form>
                    {this.getPixelInputField()}
                    <Snackbar
                        open={this.state.isCopySnackbarOpen}
                        autoHideDuration={3000}
                        onClose={this.handleCopySnackbarClose}
                    >
                        <Alert severity="success" sx={{width: '100%'}}>
                            Pixel erfolgreich kopiert.
                        </Alert>
                    </Snackbar>
                </div>
            </section>
        );
    }

    private getPixelInputField() {
        return <TextField
            label="Pixel"
            value={this.getPixel() || 'Bitte füllen Sie alle Felder aus, um einen Pixel zu generieren.'}
            multiline
            fullWidth
            variant="outlined"
            margin="normal"
            onFocus={this.handlePixelFocus}
            aria-readonly={true}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        {this.getPixel().length > 0 &&
                          <Tooltip title="Pixel kopieren">
                              <IconButton
                                  onClick={this.handleCopyClick}
                                  edge="end"
                              >
                                  <ContentCopyIcon />
                              </IconButton>
                          </Tooltip>
                        }
                    </InputAdornment>
                ),
            }}
        />;
    }

    private getAdTypeFormControl() {
        return <FormControl
            fullWidth
            variant="filled"
            margin="normal"
        >
            <InputLabel id="adType" shrink={true}>Werbeform</InputLabel>
            <Select
                labelId="adType"
                id="adType"
                data-testid="adType"
                required
                displayEmpty
                disabled={!this.state.publisher.length || !this.state.platform.length}
                value={this.state.adType}
                label="Werbeform"
                onChange={(event: SelectChangeEvent) => {
                    this.setState({adType: event.target.value as AdType});
                }}
            >
                {(!this.state.publisher.length || !this.state.platform.length) &&
                  <MenuItem disabled value="">Bitte wählen Sie einen Vermarkter und eine Platform
                    aus.</MenuItem>
                }
                {(this.state.publisher.length && this.state.platform.length) &&
                  <MenuItem disabled value="">Bitte wählen Sie eine Werbeform aus.</MenuItem>
                }
                {this.getAvailableAdTypeOptions().map((adType, index) =>
                    <MenuItem key={index} value={adType}>{this.getAdTypeLabel(adType)}</MenuItem>,
                )}
            </Select>
        </FormControl>;
    }

    private getPlatformFormControl() {
        return <FormControl
            fullWidth
            variant="filled"
            margin="normal"
        >
            <InputLabel id="platform" shrink={true}>Plattform</InputLabel>
            <Select
                labelId="platform"
                id="platform"
                data-testid="platform"
                required
                displayEmpty
                value={this.state.platform}
                label="Plattform"
                onChange={(event: SelectChangeEvent) => {
                    this.setState({platform: event.target.value as Platform});
                    this.setState({adType: ''});
                }}
            >
                <MenuItem disabled value="">Bitte wählen Sie eine Plattform aus.</MenuItem>
                {this.getAvailablePlatformOptions().map((platform, index) =>
                    <MenuItem key={index} value={platform}>{this.getPlatformLabel(platform)}</MenuItem>,
                )}
            </Select>
        </FormControl>;
    }

    private getPublisherFormControl() {
        return <FormControl
            fullWidth
            variant="filled"
            margin="normal"
        >
            <InputLabel id="publisher" shrink={true}>Vermarkter</InputLabel>
            <Select
                labelId="publisher"
                id="publisher"
                data-testid="publisher"
                required
                displayEmpty
                value={this.state.publisher}
                label="Vermarkter"
                onChange={(event: SelectChangeEvent) => {
                    this.setState({publisher: event.target.value as Publisher});
                    this.setState({adType: ''});
                }}
            >
                <MenuItem disabled value="">Bitte wählen Sie einen Vermarkter aus.</MenuItem>
                {this.getAvailablePublisherOptions().map((publisher, index) =>
                    <MenuItem key={index}
                        value={publisher}>{this.getPublisherLabel(publisher)}</MenuItem>,
                )}
            </Select>
        </FormControl>;
    }

    private getOrderIdFormControl() {
        return <FormControl
            fullWidth
            margin="normal"
        >
            <InputField
                id="orderId"
                required
                label="Order ID"
                fullWidth
                variant="filled"
                value={this.state.orderId}
                onChange={event => {
                    const value = event.target.value;
                    const filteredValue = value.replace(/[^0-9]/g, '');
                    this.setState({orderId: filteredValue});
                }}
            />
        </FormControl>;
    }

    private handlePixelFocus(event: any) {
        if (!this.getPixel().length) {
            return;
        }
        event.target.select();
    }

    private handleCopySnackbarClose = () => {
        this.setState({isCopySnackbarOpen: false});
    };

    private handleCopyClick() {
        const pixel = this.getPixel();
        if (!pixel.length) {
            return;
        }
        this.setState({isCopySnackbarOpen: true});
        navigator.clipboard.writeText(pixel);
    }

    private getPixel() {
        if (!this.state.orderId.length || !this.state.adType.length || !this.state.publisher.length || !this.state.platform.length) {
            return '';
        }

        let tagStart = '';
        let tagEnd = '';
        // eslint-disable-next-line no-template-curly-in-string
        let gdpr = '&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_44}&gdpr_pd=${GDPR_PD}';
        let baseUrl = '';

        if (this.state.platform === Platform.Display) {
            tagStart = '<img src="';
            tagEnd = '" width="1" height="1" />';
        }

        if (this.state.platform === Platform.ATV) {
            gdpr = '&adex_consent=1';
        }

        if (this.state.publisher === Publisher.SOM) {
            // eslint-disable-next-line max-len
            baseUrl = 'api.theadex.com/collector/v1/d/807/4810/i/1.gif';
        } else if (this.state.publisher === Publisher.AdAlliance) {
            // eslint-disable-next-line max-len
            baseUrl = 'api.theadex.com/collector/v1/d/1479/6187/i/1.gif';
        } else {
            throw new Error(`Unhandled Publisher: ${this.state.publisher}`);
        }

        // eslint-disable-next-line prefer-template
        return tagStart +
            'https://' +
            baseUrl +
            `?cmp=${this.getCmpParam()}` +
            `&kv=${this.getKvParam()}` +
            `&axd_mfi=${this.getAxdMfiParam()}` +
            gdpr +
            tagEnd;
    }

    private getAxdMfiParam(): string {
        const dforceId = '%dforceid%:228';

        let param = '%profile:advertisingId%:85,' +
            '%profile:idfa%:86,' +
            '%userid%:14';

        if (this.state.platform === Platform.ATV) {
            param += `,${dforceId}`;
        }

        return param;
    }

    private getKvParam(): string {
        const objectProperties: Array<IUrlParamObjectProperty> = [
            {
                key: 'trigger_type',
                value: this.getAdTypePixelKey(this.state.adType as AdType),
                encode: true,
                quote: true,
            },
            {
                key: 'traffic_type',
                value: '%profile:trafficType%',
                encode: false,
                quote: true,
            },
            {
                key: 'atv_retargeting_id',
                value: this.state.orderId,
                encode: false,
                quote: false,
            },
        ];

        return this.getFormattedParamObjectValue(objectProperties);
    }

    private getCmpParam(): string {
        const objectProperties: Array<IUrlParamObjectProperty> = [
            {
                key: 'adcaid',
                value: '%campaignid%',
                quote: false,
                encode: false,
            },
            {
                key: 'adnwid',
                value: '%profile:advertiserId%',
                quote: false,
                encode: false,
            },
            {
                key: 'adplcid',
                value: '%contentunitid%',
                quote: false,
                encode: false,
            },
            {
                key: 'adcrid',
                value: '%bannerid%',
                quote: false,
                encode: false,
            },
            {
                key: 'adacid',
                value: '%profile:networkId%',
                quote: false,
                encode: false,

            },
            {
                key: 'adsd',
                value: '%profile:domain%',
                quote: true,
                encode: false,
            },
            {
                key: 'event',
                value: 'view',
                quote: true,
                encode: true,
            },
        ];

        return this.getFormattedParamObjectValue(objectProperties);
    }

    private getFormattedParamObjectValue(paramObjectProperties: Array<IUrlParamObjectProperty>): string {
        const value = paramObjectProperties.reduce((previous, current, currentIndex) => {
            previous += encodeURIComponent(`"${current.key}":`);

            const propertyValue = current.encode ? encodeURIComponent(current.value) : current.value;
            previous += current.quote ? encodeURIComponent('"') + propertyValue + encodeURIComponent('"') : propertyValue;

            if (currentIndex < paramObjectProperties.length - 1) {
                previous += encodeURIComponent(',');
            } else {
                previous += encodeURIComponent('}');
            }

            return previous;
        }, encodeURIComponent('{'));

        return value;
    }

    private getAvailablePlatformOptions(): Array<Platform> {
        return [
            Platform.ATV,
            Platform.Video,
            Platform.Display,
        ];
    }

    private getPlatformLabel(platform: Platform): string {
        switch (platform) {
            case Platform.ATV:
                return 'ATV';
            case Platform.Video:
                return 'Video';
            case Platform.Display:
                return 'Display';
            default:
                throw new Error(`no label defined for platform: ${platform}`);
        }
    }

    private getAvailablePublisherOptions(): Array<Publisher> {
        return [
            Publisher.SOM,
            Publisher.AdAlliance,
        ];
    }

    private getPublisherLabel(publisher: Publisher): string {
        switch (publisher) {
            case Publisher.SOM:
                return 'Seven.One Media';
            case Publisher.AdAlliance:
                return 'AdAlliance';
            default:
                throw new Error(`no label defined for publisher: ${publisher}`);
        }
    }

    private getAvailableAdTypeOptions(): Array<AdType> {
        if (
            this.state.publisher === Publisher.SOM &&
            this.state.platform === Platform.ATV
        ) {
            return [
                AdType.SwitchIn,
                AdType.ATVSpot,
            ];
        } else if (
            this.state.publisher === Publisher.AdAlliance &&
            this.state.platform === Platform.ATV
        ) {
            return [
                AdType.SwitchIn,
                AdType.ATVSpot,
                AdType.SwitchInSpot,
            ];
        } else if (this.state.platform === Platform.Video) {
            return [
                AdType.PreRoll,
                AdType.PreMidRoll,
            ];
        } else if (this.state.platform === Platform.Display) {
            return [
                AdType.Billboard,
                AdType.Sitebar,
                AdType.MobileBanner,
                AdType.Understitial,
                AdType.MobileRectangle,
                AdType.AdBundle,
            ];
        }

        return [];
    }

    private getAdTypeLabel(adType: AdType): string {
        switch (adType) {
            case AdType.PreRoll:
                return 'PreRoll';
            case AdType.PreMidRoll:
                return 'Pre/MidRoll';
            case AdType.SwitchIn:
                return 'SwitchIn';
            case AdType.ATVSpot:
                return 'ATV Spot';
            case AdType.SwitchInSpot:
                return 'SwitchIn Spot';
            case AdType.Billboard:
                return 'Billboard';
            case AdType.Sitebar:
                return 'Sitebar';
            case AdType.MobileBanner:
                return 'Mobile Banner';
            case AdType.Understitial:
                return 'Understitial';
            case AdType.MobileRectangle:
                return 'Mobile Rectangle';
            case AdType.AdBundle:
                return 'Ad Bundle';
            default:
                throw new Error(`no label defined for adType: ${adType}`);
        }
    }

    private getAdTypePixelKey(adType: AdType): string {
        switch (adType) {
            case AdType.PreRoll:
                return 'preroll';
            case AdType.PreMidRoll:
                return 'premid';
            case AdType.SwitchIn:
                return 'switchin';
            case AdType.ATVSpot:
                return 'overlaySpot';
            case AdType.SwitchInSpot:
                return 'SwitchinSpot';
            case AdType.Billboard:
                return 'billboard';
            case AdType.Sitebar:
                return 'sitebar';
            case AdType.MobileBanner:
                return 'mob_banner';
            case AdType.Understitial:
                return 'understitial';
            case AdType.MobileRectangle:
                return 'mob_rectangle';
            case AdType.AdBundle:
                return 'ad_bundle';
            default:
                throw new Error(`no pixel key defined for adType: ${adType}`);
        }
    }
}

interface IUrlParamObjectProperty {
    key: string;
    value: string;
    quote: boolean;
    encode: boolean;
}

const InputField = muiStyled(TextField)(() => ({}));

export default connect(mapStateToProps, {updateMenuItems})(PixelGenerator);
